import { UserProvider } from '@auth0/nextjs-auth0'
import { MsalProvider } from '@azure/msal-react'

import { AUTH0_SYS } from './AuthComponent/AuthComponent'

import type { PublicClientApplication } from '@azure/msal-browser'
import type { SystemCode } from '@ngb-frontend/shared/types'
import type { ReactNode } from 'react'

interface AuthProviderProps {
  disabled?: boolean
  systemCode?: SystemCode
  msalApp?: PublicClientApplication
  children?: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
  const { disabled, msalApp, children, systemCode } = props

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (disabled) return <>{children}</>

  const usesAuth0 = systemCode && AUTH0_SYS.includes(systemCode)
  if (usesAuth0) return <UserProvider>{children}</UserProvider>

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {msalApp ? (
        <MsalProvider instance={msalApp}>{children}</MsalProvider>
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>{children}</>
      )}
    </>
  )
}
