import { Button, Modal } from '@velocity/ui'

import { useContent } from '@ngb-frontend/shared/context'

interface StopBookingModalProps {
  onContinue: () => void
  onStop: () => void
  open: boolean
}

export const StopBookingModal: React.FC<StopBookingModalProps> = ({
  open,
  onStop,
  onContinue,
}) => {
  const c = useContent()
  return (
    <Modal
      data-e2e-component="stop-booking-modal"
      title={c('stopBookingModal.title')}
      size="s"
      onClose={onContinue}
      open={open}
    >
      <Modal.Footer>
        <Button variant="primary-filled" onClick={onStop}>
          {c('stopBookingModal.stop')}
        </Button>
        <Button variant="dark-outlined" onClick={onContinue}>
          {c('stopBookingModal.continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
