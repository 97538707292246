import { format } from 'date-fns'
import { getDefaultOptions } from 'date-fns/getDefaultOptions'
import { formatInTimeZone as formatTZ, fromZonedTime } from 'date-fns-tz'

import { getCountryL10nConfig } from './countryLocale'
import { DEFAULT_COUNTRY } from '../countryLocaleConfig'
import { DEFAULT_DATE_FNS_LOCALE } from '../localeConfig'

import type { QueryCountryCode } from '@ngb-frontend/shared/types'
import type { Locale } from 'date-fns/types'

type DateTimeFormatWidth = 'short' | 'medium' | 'long' | 'full'
type DateTimeFormatOpts = {
  width?: DateTimeFormatWidth
  formatTime?: boolean
  manualLocale?: Locale
}

type TimeZoneFormatOpts = {
  country?: QueryCountryCode
}

export const getDateTimeFormat = (
  opts: DateTimeFormatOpts = { width: 'short', formatTime: false },
) => {
  const nxtDateFnsLocale =
    opts.manualLocale || getDefaultOptions().locale || DEFAULT_DATE_FNS_LOCALE

  return nxtDateFnsLocale.formatLong?.[opts.formatTime ? 'time' : 'date']({
    width: opts.width,
  })
}

export const formatDateTime = (
  dateTime = '',
  opts: DateTimeFormatOpts = { width: 'medium', formatTime: false },
) => {
  const nxtDateFnsLocale =
    opts.manualLocale || getDefaultOptions().locale || DEFAULT_DATE_FNS_LOCALE

  try {
    return dateTime
      ? format(
          new Date(dateTime),
          getDateTimeFormat({ ...opts, manualLocale: nxtDateFnsLocale }),
          { locale: nxtDateFnsLocale },
        )
      : '-'
  } catch {
    return dateTime
  }
}
export const formatInTimeZone = (
  utcDateTime = '',
  opts: TimeZoneFormatOpts,
) => {
  const l10NConfig = getCountryL10nConfig(opts.country ?? DEFAULT_COUNTRY) ?? {}
  const tz = l10NConfig.tz
  if (!tz) return utcDateTime

  try {
    return formatTZ(new Date(utcDateTime), tz, "yyyy-MM-dd'T'HH:mm:ss.000'Z'")
  } catch {
    return utcDateTime
  }
}

export const formatZonedToUTC = (
  zonedDateTime: Date,
  opts: TimeZoneFormatOpts,
) => {
  const l10NConfig = getCountryL10nConfig(opts.country ?? DEFAULT_COUNTRY) ?? {}
  const tz = l10NConfig.tz
  if (!tz) return zonedDateTime

  try {
    return fromZonedTime(new Date(zonedDateTime), tz)
  } catch {
    return zonedDateTime
  }
}
