import { TextArea } from '@velocity/ui'

import { useContent } from '@ngb-frontend/shared/context'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

interface AdditionalInformationFieldProps
  extends Omit<FormFieldWrapper, 'classes'> {
  className?: string
  hint?: string
}

export const AdditionalInformationField: React.FC<
  AdditionalInformationFieldProps
> = ({
  className,
  placeholder,
  name = 'additionalInformation',
  label,
  hideLabel,
  hint,
  noMargin,
}) => {
  const c = useContent()
  return (
    <FormField<string>
      name={name}
      label={label || c('fields.additionalInformation.label')}
      hideLabel={hideLabel}
      required={false}
      id="additional-information-input"
      hint={hint}
      noMargin={noMargin}
    >
      {({ input }) => (
        <TextArea
          {...input}
          placeholder={placeholder}
          inputProps={{
            'aria-label': 'addition-information',
            'data-e2e-component': 'additional-information-input',
          }}
          maxLength={1000}
          className={className}
        />
      )}
    </FormField>
  )
}
