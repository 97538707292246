import { AZURE_SYS } from '@ngb-frontend/shared/context'
import {
  SystemCode,
  type ClientConfig,
  type SharedConfig,
  type MSALClientConfig,
  type CountryCode,
  type AppLocale,
} from '@ngb-frontend/shared/types'

import { createMSALClient } from '../config/createMSALClient'

import type { SilentRequest } from '@azure/msal-browser'

const sysCodeToMsalConfig: Partial<
  Record<SystemCode, keyof SharedConfig['azure']>
> = {
  [SystemCode.SFLP]: 'azureAD',
  [SystemCode.SFALD]: 'azureEntraID',
  [SystemCode.MYAY]: 'azureB2C',
}

type MSALAppConfig = {
  client: MSALClientConfig
  request: Omit<SilentRequest, 'account'>
}

export const getMSALConfig = (
  config: SharedConfig['azure'],
  systemCode: SystemCode,
  country?: CountryCode<AppLocale>,
  locale?: AppLocale,
): MSALAppConfig => {
  const env = config?.[sysCodeToMsalConfig[systemCode] || 'azureAD']

  const baseConfig = {
    client: {
      clientId: env.clientId,
      authority: env.authority,
    },
    request: {
      scopes: [`${env.clientId}/.default`],
    },
  }

  switch (systemCode) {
    case SystemCode.MYAY:
      return {
        client: {
          ...baseConfig.client,
          knownAuthorities: [env.authority],
        },
        request: {
          scopes: ['openid', 'offline_access', env.scope || ''],
          extraQueryParameters: {
            tenant: env.tenant || '',
            country: country ? country.toLowerCase() : '',
            salesChannel: 'myald',
            partner: 'ald',
            ...(locale ? { ui_locales: locale } : {}),
          },
        },
      }
    case SystemCode.SFALD:
      return {
        ...baseConfig,
        request: {
          scopes: ['User.Read'],
          extraQueryParameters: {
            ...(locale ? { ui_locales: 'sv-se' } : {}),
          },
        },
      }
    case SystemCode.SFLP:
    default:
      return baseConfig
  }
}

export const setupMSAL = (
  config: ClientConfig['azure'],
  systemCode = SystemCode.MYLP,
  country: CountryCode<AppLocale>,
  locale: AppLocale,
  azureB2CTenant = '',
) => {
  if (!systemCode || !AZURE_SYS.includes(systemCode)) return undefined

  const systemConfig = getMSALConfig(
    { ...config, azureB2C: { ...config.azureB2C, tenant: azureB2CTenant } },
    systemCode,
    country,
    locale,
  )
  const msalClient = createMSALClient(systemConfig.client)

  return { client: msalClient, request: systemConfig.request }
}
