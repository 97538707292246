import useSWR from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'

import { useQuery } from './useQuery'

import type { RequestError } from '../error/RequestError'
import type { Driver } from '@ngb-frontend/shared/types'

export const useDriver = (id?: string, countryCode?: string) => {
  const config = useAppConfig()
  const query = useQuery()

  const driverId = id || query?.vehicleIdentifier.value

  return useSWR<Driver, RequestError>(
    // Fetches only uncached drivers. Thus, for new ids or unresolved old.
    driverId && countryCode
      ? [config.apiRoutes.driver, { driverId, countryCode }]
      : null,
  )
}
