import { useUser as useAuth0 } from '@auth0/nextjs-auth0'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import type { PropsWithChildren } from 'react'

export const Auth0AuthTemplate: React.FC<
  PropsWithChildren<{ loadingComponent?: React.FC }>
> = ({ children, loadingComponent: LoadingComponent }) => {
  const { user, isLoading } = useAuth0()
  const router = useRouter()
  // Auth0 authentication
  useEffect(() => {
    if (!user && !isLoading) {
      router.replace('/api/auth/login', undefined, { shallow: true })
    }
  }, [isLoading, router, user])

  if (!user)
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return LoadingComponent ? <LoadingComponent /> : <></>

  return children
}
