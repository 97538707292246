import { LocalstorageStore } from '@cj4/store-localstorage'
import { GoogleMapsContextProvider } from '@velocity/ui'
import { useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { SWRConfig } from 'swr'

import {
  isValidSystemCountry,
  isValidSystemService,
} from '@ngb-frontend/features/flow-config'
import {
  AuthComponent,
  AuthProvider,
  ContentProvider,
  getCountryL10nConfig,
  LocalizationProvider,
  NGBVelocityProvider,
  useAppConfig,
} from '@ngb-frontend/shared/context'
import {
  ErrorVariants,
  SystemCode,
  type QueryParams,
  type LocalStorageKeys,
} from '@ngb-frontend/shared/types'
import {
  ErrorBoundaryFallback,
  FullScreenLoader,
} from '@ngb-frontend/shared/ui'
import {
  useCleanup,
  getUserType,
  generateFetcher,
  generateCacheProvider,
  setupMSAL,
} from '@ngb-frontend/shared/utils'

import NoSSR from './NoSSR'
import ErrorPage from '../pages/error/[errorType].page'

import type { PageProps as ConfirmPageProps } from '../pages/confirm/[bookingId].page'
import type { PageProps as IndexPageProps } from '../pages/index.page'
import type { Optional, Store } from '@cj4/store'

type ChildPageProps = Partial<IndexPageProps | ConfirmPageProps>

type PageSessionProps = ChildPageProps & {
  component: React.ComponentType<ChildPageProps>
}

const invalidatingParams: (keyof QueryParams)[] = [
  'ilan',
  'service',
  'licensePlate',
  'caseId',
  'agentId',
  'systemCode',
  'country',
  'bookingId',
  'tenant',
]

type SessionInitialConfig = {
  msalInstance: ReturnType<typeof setupMSAL> | undefined
  swr: Parameters<typeof SWRConfig>[0]['value']
}

const NoSSRLoader = () => {
  return (
    <NoSSR>
      <FullScreenLoader />
    </NoSSR>
  )
}

export const PageSession: React.FC<PageSessionProps> = ({
  component: Component,
  ...childPageProps
}) => {
  const config = useAppConfig()
  const [query, setQuery] = useState<QueryParams | null | undefined>(
    undefined, // null when we can't find from cache as well
  )

  const store = useRef<Store<QueryParams | null | undefined>>(
    new LocalstorageStore(config.localStorageKeys.queryParams),
  )
  const sessionConfig = useRef<SessionInitialConfig>()
  const { clearLocalStorage, clearSWRCache } = useCleanup()

  let persistedQuery: Optional<QueryParams | null | undefined> = null
  if (typeof window !== 'undefined') {
    const storeQuery = store.current.get()
    // @ts-ignore
    persistedQuery = childPageProps?.query || storeQuery

    const resetAllCaches = invalidatingParams.some(
      (p) => persistedQuery?.[p] !== storeQuery?.[p],
    )

    if (resetAllCaches) {
      const excludedKeysFromReset: LocalStorageKeys[] = ['swrCache']
      if (persistedQuery?.bookingId)
        excludedKeysFromReset.push('bookingHistoryChecked')

      clearLocalStorage({ exclude: excludedKeysFromReset })
      clearSWRCache()
    }

    store.current.set(persistedQuery)
  }

  // Before any routing, we need to ensure that url params were read. Update
  // always the store (source of truth) except the case that it is set already
  // and url does not have new parameters or they are malformed
  useEffect(() => {
    if (persistedQuery) {
      const defaultLocaleConfig = getCountryL10nConfig(persistedQuery.country)

      const msalInstance =
        !config.disableAuth && defaultLocaleConfig.country
          ? setupMSAL(
              config.azure,
              persistedQuery.systemCode,
              defaultLocaleConfig.country,
              defaultLocaleConfig.defaultLocale,
              persistedQuery.tenant,
            )
          : undefined

      const swr = {
        fetcher: generateFetcher({
          msalInstance,
          systemCode: persistedQuery.systemCode,
        }),
        shouldRetryOnError: false,
        revalidateOnFocus: false,
        provider: generateCacheProvider(config.localStorageKeys.swrCache),
      }

      sessionConfig.current = {
        msalInstance,
        swr,
      }
    }

    store.current.set(persistedQuery)
    setQuery(persistedQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    //@ts-ignore
    childPageProps?.query,
    clearLocalStorage,
    clearSWRCache,
    config.azure,
    config.disableAuth,
    config.localStorageKeys.swrCache,
  ])

  if (query === undefined) return <FullScreenLoader />

  const ErrorVariant: ErrorVariants | undefined =
    query === null
      ? ErrorVariants.Query
      : !Object.values(SystemCode).includes(query.systemCode)
      ? ErrorVariants.SystemCode
      : !query.country
      ? ErrorVariants.Country
      : !isValidSystemCountry(query.systemCode, query.country)
      ? ErrorVariants.UnsupportedCountry
      : !isValidSystemService(getUserType(query.systemCode), query.service)
      ? ErrorVariants.Service
      : undefined

  const { msalInstance, swr } = sessionConfig.current || {}

  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <LocalizationProvider country={query?.country}>
        <NGBVelocityProvider>
          <ContentProvider>
            <AuthProvider
              systemCode={query?.systemCode}
              disabled={config.disableAuth}
              msalApp={msalInstance?.client}
            >
              <AuthComponent
                systemCode={query?.systemCode}
                loadingComponent={NoSSRLoader}
                disabled={config.disableAuth}
                azureRequest={msalInstance?.request}
              >
                <SWRConfig value={swr}>
                  <GoogleMapsContextProvider apiKey={config.google.maps || ' '}>
                    <NoSSR>
                      {!ErrorVariant ? (
                        <Component {...childPageProps} />
                      ) : (
                        <ErrorPage type={ErrorVariant} />
                      )}
                    </NoSSR>
                  </GoogleMapsContextProvider>
                </SWRConfig>
              </AuthComponent>
            </AuthProvider>
          </ContentProvider>
        </NGBVelocityProvider>
      </LocalizationProvider>
    </ErrorBoundary>
  )
}
