import { TimePicker } from '@velocity/ui'

import { useContent } from '@ngb-frontend/shared/context'
import {
  validation as v,
  defaultOperationTime,
} from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { TimeFieldProps } from '@ngb-frontend/shared/types'

export const TimeField: React.FC<TimeFieldProps> = ({
  name = 'time',
  id = 'time',
  onChange,
  minTime: minT,
  maxTime: maxT,
  renderKey,
  noMargin,
  required,
  warning,
  hint,
  hideLabel,
  label,
  ...props
}) => {
  const c = useContent()
  const minTime = minT || defaultOperationTime.minTime
  const maxTime = maxT || defaultOperationTime.maxTime
  const validator = v.compose(
    required
      ? v.apply(v.required, {
          message: c('errors.required'),
          type: 'errorType',
        })
      : undefined,
    v.apply<string>(v.isTimeNotBefore(minTime, required), {
      message: c('errors.timeNotBefore', {
        variables: { minTime },
      }),
      type: 'errorType',
    }),
    v.apply<string>(v.isTimeNotAfter(maxTime, required), {
      message: c('errors.timeNotAfter', {
        variables: { maxTime },
      }),
      type: 'errorType',
    }),
  )

  return (
    <FormField<string>
      id={id}
      name={name}
      label={label}
      aria-label={props['aria-label'] || 'time'}
      validator={validator}
      key={renderKey}
      hint={hint}
      hideLabel={hideLabel}
      noMargin={noMargin}
    >
      {({ input, meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onFocus, onBlur, ...rest } = input

        return (
          <TimePicker
            {...rest}
            required={false}
            onChange={(e) => {
              input.onChange(e.target.value)
              onChange?.(e.target.value)
            }}
            inputProps={{
              'data-e2e-component': props['data-e2e-component'] || 'time',
            }}
            minuteSteps={15}
            minTime={minTime}
            maxTime={maxTime}
            warning={!meta.error && warning}
          />
        )
      }}
    </FormField>
  )
}
