import { DatePicker } from '@velocity/ui'
import { useMemo } from 'react'

import {
  formatDateTime,
  getDateTimeFormat,
  useContent,
} from '@ngb-frontend/shared/context'
import { validation as v, dateToUTCDate } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type {
  FormFieldStyles,
  FormFieldWrapper,
} from '@ngb-frontend/shared/types'
import type { TMarginProps } from '@velocity/ui'

interface DateFieldProps
  extends Pick<
    FormFieldWrapper,
    'name' | 'label' | 'noMargin' | 'hideLabel' | 'required'
  > {
  id?: string
  classes?: {
    formField?: FormFieldStyles
    picker?: string
  }
  disabledDates?: string[]
  margin?: TMarginProps['margin']
  minDate?: string
  maxDate?: string
  hint?: string
  warning?: boolean
  error?: boolean
  renderKey?: string
  onChange?: (value: string) => void
  ['aria-label']?: string
  ['data-e2e-component']?: string
}

const shortFormat = getDateTimeFormat()

export const DateField: React.FC<DateFieldProps> = ({
  classes,
  label,
  hideLabel,
  name = 'date',
  id = 'date',
  margin,
  minDate = new Date().toISOString().split('T')[0],
  maxDate,
  hint,
  noMargin,
  warning,
  error,
  onChange,
  required,
  renderKey,
  disabledDates = [],
  ...props
}) => {
  const c = useContent()
  const utcDisabledDates = useMemo(
    () => disabledDates.map(dateToUTCDate),
    [disabledDates],
  )

  const validator = v.compose(
    required
      ? v.apply(v.validDate, {
          message: c('errors.required'),
          type: 'errorType',
        })
      : undefined,
    v.apply<string>(v.isDateNotDisabled(utcDisabledDates), {
      message: c('errors.dateNotAvailable'),
      type: 'errorType',
    }),
    v.apply<string>(v.isDateNotBefore(minDate, required), {
      message: c('errors.dateNotBefore', {
        variables: {
          minDate: formatDateTime(minDate, {
            width: 'long',
          }),
        },
      }),
      type: 'errorType',
    }),
  )

  return (
    <FormField<string>
      key={renderKey}
      id={id}
      classes={classes?.formField}
      label={label || c('fields.date.label')}
      hideLabel={hideLabel}
      name={name}
      validator={validator}
      hint={hint}
      noMargin={noMargin}
    >
      {({ input, meta }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { onFocus, onBlur, ...rest } = input

        return (
          <DatePicker
            {...rest}
            data-chromatic="ignore"
            className={classes?.picker}
            aria-label={props['aria-label'] || 'date'}
            disablePast={!minDate}
            required={false}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(e) => {
              input.onChange(e.target.value)
              onChange?.(e.target.value)
            }}
            inputProps={{
              'data-e2e-component': props['data-e2e-component'] || 'date',
            }}
            shouldDisableDate={(date) =>
              utcDisabledDates.includes(dateToUTCDate(date))
            }
            format={shortFormat}
            margin={margin}
            warning={!meta.error && warning}
            error={error}
          />
        )
      }}
    </FormField>
  )
}
