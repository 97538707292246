import { Accordion, CheckboxCardGroup, Grid, TextArea } from '@velocity/ui'
import React from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { string } from '@ngb-frontend/shared/utils'

import { useMalfunctionsFieldStyles } from './MalfunctionsField.styled'
import { FormField } from '../FormField/FormField'
import { FormFieldGroupWrapper } from '../FormFIeldGroupWrapper/FormFieldGroupWrapper'

import type {
  FormFieldWrapper,
  MalfunctionCode,
  MalfunctionFormValues,
  MalfunctionGroup,
  MalfunctionSets,
} from '@ngb-frontend/shared/types'
import type { ReactElement } from 'react'

interface MalfunctionsFieldProps
  extends Pick<FormFieldWrapper, 'label' | 'error' | 'dirty'> {
  malfunctions: MalfunctionSets
  initialValues?: MalfunctionFormValues
  row?: boolean
}

export const MalfunctionsField: React.FC<MalfunctionsFieldProps> = ({
  malfunctions,
  initialValues,
  row,
  label,
  dirty,
  error,
}) => {
  const classes = useMalfunctionsFieldStyles({ row })
  const c = useContent()

  return (
    <FormFieldGroupWrapper label={label} dirty={dirty} error={error}>
      <Grid
        className={classes.root}
        data-e2e-component="malfunctions"
        gutter={{
          x: 'var(--vel-layout-spacing-06)',
          y: 'var(--vel-layout-spacing-02)',
        }}
      >
        {
          Object.keys(malfunctions).map((group, idx) => {
            const malfunctionCodes =
              initialValues?.malfunctions?.[group as MalfunctionGroup]
            const hasNestedMalfncs =
              Array.isArray(malfunctionCodes) &&
              malfunctionCodes.filter(Boolean).length

            return (
              <Grid.Item LG={4} key={idx}>
                <Accordion
                  initialExpanded={
                    !!(hasNestedMalfncs || string(malfunctionCodes).length)
                  }
                  className={classes.accordion}
                >
                  <Accordion.Header>
                    {c(`steps.malfunction.group.${group}`)}
                  </Accordion.Header>
                  <Accordion.Content>
                    <FormField<string[]>
                      id={`malfunctions-${group}-group`}
                      name={`malfunctions.${group}`}
                      label={c(`steps.malfunction.group.${group}`)}
                      hideLabel
                      group={true}
                      required={false}
                      hideOptionalLabel={true}
                    >
                      {({ input }) => {
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const { onFocus, onBlur, onChange, ...restInput } =
                          input

                        return (
                          <CheckboxCardGroup
                            {...restInput}
                            value={input.value || []}
                            className={classes.checkboxCardGroup}
                            onChange={(e) => onChange(e.target.value)}
                            options={malfunctions[
                              group as MalfunctionGroup
                            ]?.map((m: MalfunctionCode) => ({
                              title: c(`steps.malfunction.item.${m}`, {
                                fallback: m,
                              }),
                              value: m,
                            }))}
                            data-e2e-component={`malfunctions-${group}-group`}
                          />
                        )
                      }}
                    </FormField>
                  </Accordion.Content>
                </Accordion>
              </Grid.Item>
            )
          }) as unknown as ReactElement
        }
        <Grid.Item LG={4}>
          <Accordion
            initialExpanded={!!initialValues?.malfunctionDescription?.length}
            className={classes.accordion}
          >
            <Accordion.Header>
              {c(`steps.malfunction.group.other`)}
            </Accordion.Header>
            <Accordion.Content>
              <FormField<string>
                id="malfunctions-other-group"
                name="malfunctionDescription"
                label={c(`steps.malfunction.group.other`)}
                hideLabel
                required={false}
                hideOptionalLabel={true}
              >
                {({ input }) => (
                  <TextArea
                    {...input}
                    inputProps={{
                      'data-e2e-component': 'malfunctions-other-input',
                    }}
                    maxLength={1000}
                  />
                )}
              </FormField>
            </Accordion.Content>
          </Accordion>
        </Grid.Item>
      </Grid>
    </FormFieldGroupWrapper>
  )
}
