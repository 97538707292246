import { Grid, Text, LinkInline } from '@velocity/ui'
import { FlexColumn } from '@velocity/ui/draft'
import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/shared/context'
import { ContactType } from '@ngb-frontend/shared/types'

import { FormFooter } from '../FormFooter/FormFooter'
import { OtherContactSection } from '../OtherContactSection/OtherContactSection'
import { DriverContactRadioCard } from '../RadioCard/ContactRadioCard/DriverContactRadioCard'
import { OtherContactRadioCard } from '../RadioCard/ContactRadioCard/OtherContactRadioCard'

import type { OtherContactSectionProps } from '../OtherContactSection/OtherContactSection'
import type {
  ContactFormValues,
  ContactStepValues,
} from '@ngb-frontend/shared/types'

interface ContactViewProps {
  /**
   * compact - if true the component uses its own form to manage contact fields
   */
  compact?: boolean
  driverFormValues?: ContactFormValues
  contactType?: ContactType
  /**
   * formValues - ignored when compact is passed
   */
  formValues?: ContactFormValues
  onContactChange?: (type: ContactType) => void
  disableDriver?: boolean
  onSubmit?: (values: ContactFormValues, contactType: ContactType) => void
}

const FORM_ID = 'other-contact-form'
export const nestedFormNames: NonNullable<
  OtherContactSectionProps['fieldNames']
> = {
  firstName: 'contact.firstName',
  lastName: 'contact.lastName',
  email: 'contact.email',
  phone: 'contact.phone',
}

export const ContactView: React.FC<ContactViewProps> = (props) => {
  const {
    compact,
    driverFormValues,
    onSubmit,
    contactType,
    formValues,
    onContactChange,
    disableDriver,
  } = props
  const otherContactSelected = contactType === ContactType.Other

  const onChangeSelectedContact = useCallback(
    (type: ContactStepValues['contactType']) => {
      onContactChange?.(type)
    },
    [onContactChange],
  )

  const c = useContent()
  const policyLink = useMemo(() => {
    const privacyLinkText = c('steps.shared.privacyStatement')
    return c.rich('steps.shared.contactPrivacy', {
      variables: {
        link: (chunks) => (
          <LinkInline href={Array.isArray(chunks) ? chunks.join() : chunks}>
            {privacyLinkText}
          </LinkInline>
        ),
      },
    })
  }, [c])

  const handleOtherContactSubmit = useCallback(
    (values: ContactFormValues) => {
      onSubmit?.(values, ContactType.Other)
    },
    [onSubmit],
  )

  const handleDriverContactSubmit = useCallback(() => {
    if (driverFormValues) onSubmit?.(driverFormValues, ContactType.Driver)
  }, [driverFormValues, onSubmit])

  return (
    <Grid>
      <Grid.Item LG={4}>
        {!compact && (
          <Text variant="100" bold>
            {c('fields.userType.label')}
          </Text>
        )}
        {driverFormValues && (
          // Replace with UserTypeField
          <DriverContactRadioCard
            onChange={onChangeSelectedContact}
            id={ContactType.Driver}
            checked={!otherContactSelected}
            disabled={disableDriver}
            {...driverFormValues}
          />
        )}
        <OtherContactRadioCard
          onChange={onChangeSelectedContact}
          checked={otherContactSelected}
        />
        {compact && otherContactSelected && (
          <Form<ContactFormValues>
            onSubmit={handleOtherContactSubmit}
            initialValues={formValues}
          >
            {(formProps) => (
              <form
                onSubmit={formProps.handleSubmit}
                data-e2e-component="contact-form"
                id={FORM_ID}
              >
                <OtherContactSection compact />
              </form>
            )}
          </Form>
        )}
        <Text variant="100">{policyLink}</Text>
        {compact && (
          <FlexColumn mt={5}>
            <FormFooter
              disabled={contactType === undefined}
              form={FORM_ID}
              onClick={
                // When this is undefined the form submit is triggered instead
                otherContactSelected ? undefined : handleDriverContactSubmit
              }
            />
          </FlexColumn>
        )}
      </Grid.Item>
      {!compact ? (
        <Grid.Item LG={4}>
          <OtherContactSection
            isHidden={!otherContactSelected}
            fieldNames={nestedFormNames}
          />
        </Grid.Item>
      ) : (
        <Grid.Item LG={0} />
      )}
    </Grid>
  )
}
