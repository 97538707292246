import { DEFAULT_LOCALE } from './localeConfig'

import type {
  AppLocale,
  CountryCode,
  QueryCountryCode,
} from '@ngb-frontend/shared/types'

export const DEFAULT_COUNTRY: QueryCountryCode = 'GLOBAL'

type CountryL10n = Record<
  `${QueryCountryCode}`,
  {
    country?: CountryCode<AppLocale>
    tz?: string
    locales: AppLocale[]
    defaultLocale: AppLocale
  }
>

export const countryL10n: CountryL10n = {
  [DEFAULT_COUNTRY]: {
    locales: [DEFAULT_LOCALE],
    defaultLocale: DEFAULT_LOCALE,
  },
  // lp systems
  LPUK: {
    country: 'gb',
    tz: 'Europe/London',
    locales: ['en-GB'],
    defaultLocale: 'en-GB',
  },
  // ayvens subsidiary codes
  '4001': {
    country: 'at',
    tz: 'Europe/Vienna',
    locales: ['de-AT', 'en-AT'],
    defaultLocale: 'de-AT',
  },
  '5601': {
    country: 'be',
    tz: 'Europe/Brussels',
    locales: ['nl-BE', 'en-BE'],
    defaultLocale: 'en-BE',
  },
  '25001': {
    country: 'fr',
    tz: 'Europe/Paris',
    locales: ['fr-FR', 'en-FR'],
    defaultLocale: 'fr-FR',
  },
  '25002': {
    country: 'fr',
    tz: 'Europe/Paris',
    locales: ['fr-FR', 'en-FR'],
    defaultLocale: 'fr-FR',
  },
  '25003': {
    country: 'fr',
    tz: 'Europe/Paris',
    locales: ['fr-FR', 'en-FR'],
    defaultLocale: 'fr-FR',
  },
  '6420': {
    country: 'ro',
    tz: 'Europe/Bucharest',
    locales: ['ro-RO', 'en-RO'],
    defaultLocale: 'ro-RO',
  },
  '48401': {
    country: 'mx',
    tz: 'America/Mexico_City',
    locales: ['es-MX', 'en-MX'],
    defaultLocale: 'es-MX',
  },
  '75201': {
    country: 'se',
    tz: 'Europe/Stockholm',
    locales: ['sv-SE', 'en-SE'],
    defaultLocale: 'sv-SE',
  },
  '75202': {
    country: 'se',
    tz: 'Europe/Stockholm',
    locales: ['sv-SE', 'en-SE'],
    defaultLocale: 'sv-SE',
  },
  '75601': {
    country: 'ch',
    tz: 'Europe/Zurich',
    locales: ['fr-CH', 'de-CH', 'it-CH', 'en-CH'],
    defaultLocale: 'de-CH',
  },
}
