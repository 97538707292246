import { Organization } from '@ngb-frontend/shared/types'

import { systemCodeToOrg } from '../locale/countryCodeToOrg'

import type { SystemCode } from '@ngb-frontend/shared/types'

export const isLPSystem = (systemCode?: string) => {
  return !!(
    systemCode && systemCodeToOrg[systemCode as SystemCode] === Organization.LP
  )
}
