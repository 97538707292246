import { getDateFnsLocale } from './locale'
import { countryL10n, DEFAULT_COUNTRY } from '../countryLocaleConfig'

export const getCountryL10nConfig = (country = DEFAULT_COUNTRY) => {
  const conf = countryL10n[country] || {}
  const dateFnsLocale = getDateFnsLocale(conf.country)
  return {
    ...conf,
    formatLocale: dateFnsLocale,
  }
}
