import { Grid } from '@velocity/ui'
import React from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/shared/context'
import { AdditionalInformationField, FormFooter } from '@ngb-frontend/shared/ui'

import { useAdditionalInformationFormStyles } from './AdditionalInformationForm.styled'

import type {
  AdditionalInformationFormValues,
  StepFormProps,
} from '@ngb-frontend/shared/types'

export const AdditionalInformationForm: React.FC<
  StepFormProps<AdditionalInformationFormValues>
> = ({ initialValues, onSubmit }) => {
  const c = useContent()
  const classes = useAdditionalInformationFormStyles()

  const handleSubmit = React.useCallback(
    ({ additionalInformation }: AdditionalInformationFormValues) => {
      onSubmit?.({ additionalInformation: additionalInformation || '' })
    },
    [onSubmit],
  )

  return (
    <Form<AdditionalInformationFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
    >
      {(formProps) => (
        <form
          onSubmit={formProps.handleSubmit}
          data-e2e-component="additional-information-form"
        >
          <Grid>
            <Grid.Item LG={6}>
              <AdditionalInformationField
                hideLabel
                placeholder={c('fields.additionalInformation.placeholder')}
                className={classes.field}
                hint={c('fields.additionalInformation.hint')}
              />
            </Grid.Item>
          </Grid>
          <FormFooter />
        </form>
      )}
    </Form>
  )
}
