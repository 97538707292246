import { ChevronDownIcon, ChevronUpIcon } from '@velocity/icons/system'
import { Grid, Text } from '@velocity/ui'
import React, { useCallback, useState } from 'react'
import { Form } from 'react-final-form'

import { useContent } from '@ngb-frontend/shared/context'
import { DateTimeField, FormFooter, LinkInline } from '@ngb-frontend/shared/ui'
import { setDateTime } from '@ngb-frontend/shared/utils'

import type {
  DateFormValues,
  StepFormProps,
  TimeFieldProps,
} from '@ngb-frontend/shared/types'
import type { FormRenderProps } from 'react-final-form'

// countryconfig
const nrOfAlternativeDates = 3

export const DateForm: React.FC<
  StepFormProps<DateFormValues> & {
    minDate?: string
    hint?: string
    fixedTime?: string
    timeConfig?: TimeFieldProps
  }
> = ({ onSubmit, initialValues, minDate, timeConfig, hint, fixedTime }) => {
  const c = useContent()
  const [showAlternativeDates, setShowAlternativeDates] = useState(
    !!initialValues?.alternativeDates?.length,
  )
  const handleToggleAlternativeDates = useCallback(
    () => setShowAlternativeDates((prev) => !prev),
    [],
  )
  const handleSubmit = React.useCallback(
    (values: DateFormValues) => {
      const { date, time: formTime, alternativeDates } = values
      const time = fixedTime || formTime
      const dateTime = setDateTime(date, fixedTime || time)

      onSubmit?.({
        date: dateTime.toISOString(),
        time,
        alternativeDates: alternativeDates?.filter((a) => a.date),
      })
    },
    [fixedTime, onSubmit],
  )

  const renderAlternativeDateFields = useCallback(
    (formProps: FormRenderProps<DateFormValues, Partial<DateFormValues>>) => (
      <>
        {Array.from({ length: nrOfAlternativeDates }).map((_, idx) => (
          <DateTimeField
            key={idx}
            dateProps={{
              required: !!formProps.values.alternativeDates?.[idx]?.time,
              onChange: (date) =>
                formProps.form.mutators.setPreferredTime(date, idx),
              min: minDate,
              name: `alternativeDates[${idx}].date`,
              id: `alternative-date-${idx}`,
              'data-e2e-component': `alternative-date-${idx}`,
              'aria-label': `alternative date ${idx}`,
            }}
            timeProps={{
              required: !!formProps.values.alternativeDates?.[idx]?.date,
              min: timeConfig?.minTime,
              max: timeConfig?.maxTime,
              name: `alternativeDates[${idx}].time`,
              id: `alternative-time-${idx}`,
              'data-e2e-component': `alternative-time-${idx}`,
              'aria-label': `alternative time ${idx}`,
            }}
            showTimeField={!fixedTime}
            inline
          />
        ))}
      </>
    ),
    [minDate, timeConfig, fixedTime],
  )

  return (
    <Form<DateFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      mutators={{
        setPreferredTime: ([date, idx], state, { changeValue }) => {
          if (
            !state.lastFormState?.values.alternativeDates?.[idx].time &&
            date
          ) {
            changeValue(
              state,
              `alternativeDates[${idx}].time`,
              () => state.lastFormState?.values.time,
            )
          }
        },
      }}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit} data-e2e-component="date-form">
          <Grid>
            <Grid.Item LG={4}>
              <DateTimeField
                hint={hint}
                showTimeField={!fixedTime}
                dateProps={{
                  required: true,
                  min: minDate,
                }}
                timeProps={{
                  required: true,
                  min: timeConfig?.minTime,
                  max: timeConfig?.maxTime,
                }}
                inline
              />
              <Text margin={{ bottom: '07' }}>
                {c('steps.date.multipleDates')}
              </Text>
              <LinkInline
                StartIcon={
                  showAlternativeDates ? ChevronUpIcon : ChevronDownIcon
                }
                onClick={handleToggleAlternativeDates}
                margin={{ bottom: '07' }}
                data-e2e-component="alternative-dates-button"
              >
                {c('steps.date.addAlternativeDates')}
              </LinkInline>
              {showAlternativeDates && renderAlternativeDateFields(formProps)}
              <FormFooter />
            </Grid.Item>
          </Grid>
        </form>
      )}
    </Form>
  )
}
