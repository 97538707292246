import type { MalfunctionSets } from '@ngb-frontend/shared/types'

export const staticMnRDCCMalfunctions: MalfunctionSets = {
  lighting: [
    'HeadLightNotWorking',
    'DayRunningLightsNotWorking',
    'FrontFogLightsNotWorking',
    'HeadlightHeigthAdjustmentNotWorking',
    'HeadlightLightSensorNotWorking',
    'TailLightsNotWorking',
    'BrakeLightsNotWorking',
    'RearFogLightsNotWorking',
    'ReverseLightsNotWorking',
    'SteeringDirectionIndicatorNotWorking',
    'InteriorLightsNotWorking',
    'LightNotWorking',
  ],
  steering: [
    'VibrationThroughSteering',
    'CheckWheelAlignment',
    'CheckSteeringWheelAlignment',
    'SteeringWheelAdjustmentNotWorking',
  ],
  warnings: [
    'TpmsWarningLightOn',
    'OilLightOn',
    'WarningLightOnDashboard',
    'BrakeWarningLightOn',
    'WarningMessageOnDashboard',
    'AirbagWarningLightOn',
  ],
  fluidCheck: [
    'OilCoolantBrakeFluidNeedToBeFilled',
    'AdblueRequired',
    'CoolantLeak',
    'FuelLeak',
    'OilLeak',
    'FluidLeak',
  ],
  climateHeating: [
    'AirConNotWorking',
    'VehicleHeaterNotWorking',
    'SeatHeatingNotWorking',
    'SeatCoolingNotWorking',
    'SteeringWheelHeatingNotWorking',
    'MirrorHeatingNotWorking',
    'WindscreenHeatingNotWorking',
    'RearWindowHeatingNotWorking',
  ],
  issues: [
    'BootBonnetDoorProblem',
    'CarIsOverheating',
    'CarIsSmoking',
    'GearboxNoise',
    'HardToSelectGears',
    'ClutchIssue',
    'NoiseFromEngine',
    'NoiseFromVehicle',
    'SuspensionNoiseKnocking',
    'VehicleInLimpModeOrLossOfPower',
    'BrakesNoisy',
    'PoorBrakes',
    'GloveCompartmentNotOpening',
    'SunRoofIssues',
    'SunRoofNotWorking',
    'OdourFromVehicle',
    'TachometerNotWorking',
    'SpeedometerNotWorking',
    'OdometerNotWorking',
    'SeatAdjustmentNotWorking',
    'SafetyBeltIssues',
  ],
  windscreenWipers: [
    'WipersNotClearing',
    'FrontWipersNotWorking',
    'RearWiperNotWorking',
    'RainSensorNotWorking',
  ],
  electrical: [
    'RemoteKeyNotWorking',
    'BluetoothRadioNotWorking',
    'SatnavSoftwareNotWorking',
    'BatteryFlat',
    'WindowWontOpen',
    'VehicleNotCharging',
    'ChargingIssues',
    'AlarmNotWorking',
    'AlarmIssues',
    'MirrorAdjustmentNotWorking',
    'MirrorFoldingNotWorking',
    'CigaretteLighterNotWorking',
    'PhoneChargingNotWorking',
    'ElectricalIssue',
  ],
}

export const staticMalfunctions = staticMnRDCCMalfunctions
