import { enGB } from 'date-fns/locale/en-GB'

import { dateFnsLocales, DEFAULT_LOCALE } from '../localeConfig'

import type { AppLocale } from '@ngb-frontend/shared/types'

const isSupportedLocale = (
  locale?: string,
): locale is keyof typeof dateFnsLocales => {
  return !!locale && !!dateFnsLocales[locale as AppLocale]
}

export const getDateFnsLocale = (id?: string) => {
  const mappedLocale = isSupportedLocale(id)
    ? dateFnsLocales[id]
    : dateFnsLocales[DEFAULT_LOCALE]

  return mappedLocale || enGB
}
