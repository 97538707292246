import { LocalstorageStore } from '@cj4/store-localstorage'
import { useMemo, useRef } from 'react'

import { useAppConfig } from '@ngb-frontend/shared/context'
import {
  AppTheme,
  TVehicleIdentifier,
  type QueryParams,
} from '@ngb-frontend/shared/types'

import { getUserType } from '../config/getUserType'

import type { Store } from '@cj4/store'

/**
 * @returns The parsed query params from LS augmented with query-specific,
 * repeatable fields/helpers which the app uses.
 */
export const useQuery = () => {
  const config = useAppConfig()
  const store = useRef<Store<QueryParams | null>>(
    new LocalstorageStore(config.localStorageKeys.queryParams),
  )

  const q = store.current.get()

  const qRes = useMemo(() => {
    if (!q) return q

    const { ilan, licensePlate } = q
    return {
      ...q,
      vehicleIdentifier: {
        type: ilan ? TVehicleIdentifier.ILAN : TVehicleIdentifier.REGISTRATION,
        // We don't store a query if none of the 2 is present
        value: (ilan || licensePlate) as string,
      },
      userType: getUserType(q.systemCode),
      theme: AppTheme.Ayvens,
    }
  }, [q])

  return qRes
}
