import { Button, Grid } from '@velocity/ui'
import React from 'react'

import { useContent } from '@ngb-frontend/shared/context'

import type { MouseEvent } from 'react'

interface FormFooterProps {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  children?: string
  form?: string
}

export const FormFooter: React.FC<FormFooterProps> = ({
  disabled,
  children,
  onClick,
  form,
}) => {
  const c = useContent()

  return (
    <Grid>
      <Grid.Item LG={4}>
        <Button
          margin={{ top: '04' }}
          disabled={disabled}
          data-e2e-component="submit-button"
          type={onClick ? 'button' : 'submit'}
          onClick={onClick}
          variant="primary-filled"
          fullWidth
          form={form}
        >
          {children || c('steps.shared.next')}
        </Button>
      </Grid.Item>
    </Grid>
  )
}
