import { getTestSharedConfig } from './getTestSharedConfig'

import type { ClientConfig } from '@ngb-frontend/shared/types'

export const getTestClientConfig = (): ClientConfig => ({
  ...getTestSharedConfig(),
  onetrust: '',
  google: {
    maps: '12345',
  },
  features: {
    driverCourtesy: true,
    eligibilityCheck: false,
  },
  hotjarConfig: {
    id: '',
    version: 6,
  },
  disableAyvensOnLP: true,
  localStorageKeys: {
    swrCache: 'appCache',
    flow: 'ngb-flow',
    queryParams: 'queryParams',
    bookingHistoryChecked: 'bookingHistoryChecked',
    customPlatesChecked: 'customPlatesChecked',
  },
})
