import { Input } from '@velocity/ui'

import { useContent } from '@ngb-frontend/shared/context'
import { validation as v } from '@ngb-frontend/shared/utils'

import { FormField } from '../FormField/FormField'

import type { FormFieldWrapper } from '@ngb-frontend/shared/types'

export const NameField: React.FC<FormFieldWrapper> = ({
  classes,
  name = 'name',
  label,
  hideLabel,
  disabled,
  noMargin,
  noValidation,
  placeholder,
  maxLength,
}) => {
  const c = useContent()
  return (
    <FormField<string>
      name={name}
      label={label || c('fields.name.label')}
      hideLabel={hideLabel}
      id={`${name}-input`}
      validator={v.apply(v.required, {
        message: c('errors.required'),
        type: 'errorType',
      })}
      noValidation={noValidation}
      classes={classes}
      noMargin={noMargin}
      disabled={!!disabled}
    >
      {({ input }) => (
        <Input
          inputProps={{
            'data-e2e-component': `${name}-input`,
          }}
          {...input}
          onChange={(e) => {
            const value = e.target.value

            if (maxLength && value.length > maxLength) {
              return
            }

            input.onChange(e)
          }}
          required={false}
          placeholder={placeholder ?? c('fields.name.placeholder')}
          disabled={!!disabled}
        />
      )}
    </FormField>
  )
}
