import {
  CarDamagePictogram,
  ContractEndPictogram,
  CarPictogram,
} from '@velocity/icons/pictogram'
import { LinkInline } from '@velocity/ui'
import React from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { ErrorVariants, UserType } from '@ngb-frontend/shared/types'
import {
  formatPhoneNumber,
  systemCodeToReferrer,
  useQuery,
} from '@ngb-frontend/shared/utils'

import { NotificationView } from '../NotificationView/NotificationView'

import type { NotificationViewProps } from '../NotificationView/NotificationView'

const DriverVariants: Partial<Record<ErrorVariants, string>> = {
  [ErrorVariants.SystemCode]: 'systemCode',
  [ErrorVariants.Vehicle]: 'vehicle',
  [ErrorVariants.ContractInactive]: 'contractInactiveDriver',
  [ErrorVariants.Country]: 'country',
  [ErrorVariants.UnsupportedCountry]: 'unsupportedCountry',
}

const ErrorIcons = {
  [ErrorVariants.Contract]: ContractEndPictogram,
  [ErrorVariants.ContractMalformed]: ContractEndPictogram,
  [ErrorVariants.ContractInactive]: CarPictogram,
  [ErrorVariants.Generic]: CarDamagePictogram,
}

export interface NotificationViewErrorProps
  extends Pick<NotificationViewProps, 'onSubmit' | 'Icon'> {
  type: UserType
  variant?: ErrorVariants
}

export const NotificationViewError: React.FC<NotificationViewErrorProps> = ({
  type,
  onSubmit,
  variant = ErrorVariants.Generic,
  // @ts-ignore
  Icon = ErrorIcons[variant] || ErrorIcons[ErrorVariants.Generic],
}) => {
  const c = useContent()
  const query = useQuery()
  // Missing initialization parameters or there is a user/service mismatch
  if (type === UserType.Unknown || variant === ErrorVariants.Service) {
    return (
      <NotificationView
        Icon={Icon}
        headline={c(`notifications.error.titles.${variant}`)}
      />
    )
  }

  const isDriver = type === UserType.Driver
  const errorVariant = isDriver
    ? DriverVariants[variant] || ErrorVariants.Generic
    : variant

  return (
    <NotificationView
      Icon={Icon}
      headline={c(`notifications.error.titles.${errorVariant}`)}
      submitButtonText={c('notifications.error.submit', {
        variables: { referer: systemCodeToReferrer(query?.systemCode) },
      })}
      submitButtonVariant="secondary-outlined"
      onSubmit={onSubmit}
    >
      {isDriver && !DriverVariants[variant] ? (
        <>
          {`${c('notifications.error.contactUs')} `}
          <LinkInline>
            {formatPhoneNumber(c('steps.shared.phoneNumber'))}
          </LinkInline>
        </>
      ) : (
        c(`notifications.error.content.${errorVariant}`, {
          fallback: 'notifications.error.contactHelpdesk',
        })
      )}
    </NotificationView>
  )
}
