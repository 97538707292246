import AyvensTheme from '@velocity/styling/themes/ayvens/theme'
import { VelocityProvider } from '@velocity/ui'
import { useMemo } from 'react'

import { useLocalization } from '../LocaleContext/LocaleContext'

import type { DateFnsLocales, VelocityProviderProps } from '@velocity/ui'

export const NGBVelocityProvider: React.FC<Partial<VelocityProviderProps>> = (
  props,
) => {
  const { locale, formatLocale } = useLocalization()
  const formatingLocales = useMemo((): DateFnsLocales => {
    return { [locale]: formatLocale }
  }, [formatLocale, locale])

  return (
    <VelocityProvider
      {...props}
      locale={locale}
      dateFnsLocales={formatingLocales}
      theme={AyvensTheme}
    />
  )
}
