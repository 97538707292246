import { DEFAULT_LOCALE } from '@ngb-frontend/shared/context'

export const localeToCountryISOCode = (locale: Intl.Locale['baseName']) => {
  try {
    const countryCode = new Intl.Locale(locale).region
    return countryCode
  } catch (error) {
    const errMsg = `Invalid locale: ${locale}, falling back to ${DEFAULT_LOCALE}`
    // eslint-disable-next-line no-console
    console.error(errMsg) // NOSONAR

    return new Intl.Locale(DEFAULT_LOCALE).region
  }
}
