import { getDefaultOptions } from 'date-fns'
import useSWR from 'swr'

import { DEFAULT_LOCALE } from '@ngb-frontend/shared/context'

import type {
  OSMGeoCodeJSONRes,
  PlaceGeolocationCoords,
} from '@ngb-frontend/shared/types'

export const useReverseOSM = (props?: PlaceGeolocationCoords) => {
  const { longitude: lon, latitude: lat } = props || {}

  const nominatimApiUrl = `https://nominatim.openstreetmap.org/reverse?format=geocodejson&addressdetails=1&lon=${lon}
    &lat=${lat}&accept-language=${getDefaultOptions().locale || DEFAULT_LOCALE}`

  return useSWR<OSMGeoCodeJSONRes>(lon && lat ? [nominatimApiUrl] : null)
}
