import { Grid } from '@velocity/ui'
import * as R from 'ramda'
import React from 'react'

import { useContent, useAppConfig } from '@ngb-frontend/shared/context'
import { CardButton } from '@ngb-frontend/shared/ui'
import {
  formatCategory,
  useQuery,
  useVehicle,
} from '@ngb-frontend/shared/utils'

import type {
  ReplacementVehicleStepPrerequisiteData,
  ReplacementVehicleStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const ReplacementVehicleStep: React.FC<
  StepProps<
    ReplacementVehicleStepValues,
    ReplacementVehicleStepPrerequisiteData
  >
> = ({ onNextStep, onUpdateStep }) => {
  const c = useContent()
  const config = useAppConfig()
  const query = useQuery()
  const { data: vehicle } = useVehicle({
    // Always pass an ilan and/or licensePlate to this hook call to enable app state overrides
    vehicleIdentifier: query?.vehicleIdentifier,
    country: query?.country,
  })

  const handleSubmit = React.useCallback(
    (data: ReplacementVehicleStepValues) => {
      onUpdateStep(data)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <>
      <Grid>
        <Grid.Item
          LG={4}
          style={{ display: config.features.driverCourtesy ? 'block' : 'none' }}
        >
          <CardButton
            label={c('steps.replacementVehicle.free')}
            title={c('steps.replacementVehicle.courtesyVehicle')}
            onClick={() =>
              handleSubmit({ replacementVehicle: false, courtesyVehicle: true })
            }
            data-e2e-component="courtesy-vehicle-button"
          >
            {c('steps.replacementVehicle.categoryAB')}
          </CardButton>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item LG={4}>
          <CardButton
            title={c('steps.replacementVehicle.rentalVehicle')}
            disabled={!vehicle?.extraServices.ReplacementVehicle}
            onClick={() =>
              handleSubmit({
                replacementVehicle: true,
                courtesyVehicle: false,
                replacementVehicleCategory: vehicle?.replacementVehicleCategory,
              })
            }
            data-e2e-component="rental-vehicle-button"
          >
            {vehicle?.extraServices.ReplacementVehicle
              ? c('steps.replacementVehicle.category', {
                  variables: {
                    category: formatCategory(
                      vehicle?.replacementVehicleCategory || '',
                    ),
                  },
                })
              : c('steps.replacementVehicle.noRental')}
          </CardButton>
        </Grid.Item>
      </Grid>
    </>
  )
}

export const hasReplacementVehicleValues = (
  x: unknown,
): x is ReplacementVehicleStepValues =>
  R.allPass([
    R.pipe(R.prop('replacementVehicle'), R.is(Boolean)),
    R.pipe(R.prop('courtesyVehicle'), R.is(Boolean)),
  ])(x)

export default ReplacementVehicleStep
