import type {
  MalfunctionCode,
  MalfunctionGroup,
  MalfunctionSets,
} from '@ngb-frontend/shared/types'

export const malfunctionSets: MalfunctionSets = {
  lighting: [
    'HeadLightNotWorking',
    'DayRunningLightsNotWorking',
    'FrontFogLightsNotWorking',
    'HeadlightHeigthAdjustmentNotWorking',
    'HeadlightLightSensorNotWorking',
    'TailLightsNotWorking',
    'BrakeLightsNotWorking',
    'RearFogLightsNotWorking',
    'ReverseLightsNotWorking',
    'SteeringDirectionIndicatorNotWorking',
    'InteriorLightsNotWorking',
    'LightNotWorking',
  ],
}

export const malfunctionFormData: Partial<
  Record<MalfunctionGroup, MalfunctionCode[]>
> = Object.entries(malfunctionSets).reduce((acc, [set, setItems]) => {
  if (!setItems.length) return acc

  return {
    ...acc,
    [set]: setItems,
  }
}, {})
