import { Footer as AyvensFooter } from '@velocity/ui'
import React, { useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { AppTheme } from '@ngb-frontend/shared/types'
import { useQuery } from '@ngb-frontend/shared/utils'

import { FOOTER_LINKS } from './footerLinks'

export const Footer: React.FC = () => {
  const c = useContent()
  const { legal, target } = FOOTER_LINKS
  const query = useQuery()
  const date = useMemo(() => new Date().getFullYear(), [])

  const OneTrustBtn = useMemo(() => {
    // @ts-ignore
    const displayOTInfo = window.OneTrust?.ToggleInfoDisplay
    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!displayOTInfo) return

    return (
      <AyvensFooter.Legal.Link onClick={() => displayOTInfo()}>
        {c('footer.manageCookies')}
      </AyvensFooter.Legal.Link>
    )
  }, [c])

  const copyRightComponent = useMemo(
    () => <span data-chromatic="ignore">{`© ${date} ${AppTheme.Ayvens}`}</span>,
    [date],
  )

  return (
    <AyvensFooter
      layoutContainerSize="fixed-wide"
      variant="brand"
      copyright={copyRightComponent}
    >
      <AyvensFooter.Legal>
        {OneTrustBtn}
        {legal.map(({ label, link }) => (
          <AyvensFooter.Legal.Link
            href={c(`footer.${link}`)}
            target={target}
            key={link}
          >
            {c(`footer.${label}`)}
          </AyvensFooter.Legal.Link>
        ))}
      </AyvensFooter.Legal>
    </AyvensFooter>
  )
}
