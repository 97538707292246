import { CarDamagePictogram } from '@velocity/icons/pictogram'
import { FlexBox } from '@velocity/ui/draft'
import { useMemo } from 'react'

import { useContent } from '@ngb-frontend/shared/context'
import { UserType } from '@ngb-frontend/shared/types'

import { NotificationView } from '../NotificationView/NotificationView'
import { NotificationViewError } from '../NotificationViewError/NotificationViewError'
import { StepLayout as Layout } from '../StepLayout/StepLayout'

import type { FallbackProps } from 'react-error-boundary'

// NB: In dev environment, the NextJS error overlay will still be shown
// https://github.com/vercel/next.js/discussions/13387

export const boundaryErrors = {
  unknownPath: 'Unknown step path',
}

export const ErrorBoundaryFallback: React.FC<FallbackProps> = ({ error }) => {
  const c = useContent()

  const ErrorComponent = useMemo(() => {
    if (error.message === boundaryErrors.unknownPath) {
      return (
        <NotificationView
          Icon={CarDamagePictogram}
          headline={c(`errors.path`)}
        />
      )
    }

    return <NotificationViewError type={UserType.Unknown} />
  }, [c, error.message])

  return (
    <Layout navLabel={''} showLogo={true}>
      <FlexBox>{ErrorComponent}</FlexBox>
    </Layout>
  )
}
