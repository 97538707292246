import useSWR from 'swr'

import { useAppConfig } from '@ngb-frontend/shared/context'
import {
  BookingServiceCode,
  type QueryCountryCode,
  type Vehicle,
  type VehicleIdentifier,
} from '@ngb-frontend/shared/types'

import { useQuery } from './useQuery'
import { isLPSystem } from '../system/isLPSystem'

import type { RequestError } from '../error/RequestError'

type Props = {
  vehicleIdentifier?: VehicleIdentifier
  country?: QueryCountryCode
  service?: BookingServiceCode
}

/**
 * @param vehicleIdentifier - The vehicleIdentifier to use for fetching, if not provided, it will try to get it from the query.
 */
export const useVehicle = (props?: Props) => {
  const { vehicleIdentifier, country, service } = props || {}

  const config = useAppConfig()
  const initQuery = useQuery()

  const resolvedVehicleId = vehicleIdentifier || initQuery?.vehicleIdentifier
  const resolvedCountry = country || initQuery?.country
  const resolvedService = service || initQuery?.service
  return useSWR<Vehicle, RequestError>(
    // Fetches only uncached contracts. Thus, for new ILANs or unresolved old.
    isLPSystem(initQuery?.systemCode) && resolvedVehicleId
      ? [
          config.apiRoutes.vehicle,
          {
            vehicleIdentifier: resolvedVehicleId,
            country: resolvedCountry,
            isEoL: resolvedService === BookingServiceCode.EoL,
          },
        ]
      : null,
  )
}
