import { NotificationBanner, Grid, List } from '@velocity/ui'
import * as R from 'ramda'
import React from 'react'

import { useContent, staticMalfunctions } from '@ngb-frontend/shared/context'
import { PhoneButton } from '@ngb-frontend/shared/ui'
import { getMalfunctionStepDataFromBooking } from '@ngb-frontend/shared/utils'

import { MalfunctionForm } from '../../forms/MalfunctionForm/MalfunctionForm'

import type {
  MalfunctionFormValues,
  MalfunctionStepPrerequisiteData,
  MalfunctionStepValues,
  StepProps,
} from '@ngb-frontend/shared/types'

const MalfunctionStep: React.FC<
  StepProps<MalfunctionStepValues, MalfunctionStepPrerequisiteData>
> = ({ onNextStep, onUpdateStep, stepData, booking }) => {
  const c = useContent()

  const initialValuesRef = React.useRef<MalfunctionStepValues | undefined>(
    hasMalfunctionFormValues(stepData)
      ? {
          malfunctions: stepData.malfunctions,
          malfunctionDescription: stepData.malfunctionDescription,
        }
      : booking
      ? getMalfunctionStepDataFromBooking(booking)
      : undefined,
  )

  const handleSubmit = React.useCallback(
    (values: MalfunctionFormValues) => {
      onUpdateStep(values)
      onNextStep()
    },
    [onNextStep, onUpdateStep],
  )

  return (
    <>
      <Grid>
        <Grid.Item LG={6}>
          <NotificationBanner
            kind="warning"
            title={c('steps.malfunction.notification.title')}
            data-e2e-component="contact-notification-banner"
            margin={{
              bottom: '08',
            }}
          >
            <List margin={{ bottom: '04' }}>
              <List.Item>
                {c('steps.malfunction.notification.options.unsafe')}
              </List.Item>
              <List.Item>
                {c('steps.malfunction.notification.options.warningLights')}
              </List.Item>
            </List>
            <PhoneButton />
          </NotificationBanner>
        </Grid.Item>
      </Grid>
      <MalfunctionForm
        malfunctions={staticMalfunctions}
        onSubmit={handleSubmit}
        initialValues={initialValuesRef.current}
      />
    </>
  )
}

export const hasMalfunctionFormValues = (x: unknown) =>
  R.allPass([
    R.pipe(
      R.prop('malfunctions'),
      R.when(R.is(Object), R.pipe(R.values, R.flatten, R.length)),
    ),
  ])(x) ||
  R.allPass([R.pipe(R.prop('malfunctionDescription'), R.is(String))])(x)

export default MalfunctionStep
